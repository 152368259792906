<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="来信标题">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="内容摘要">
          <a-textarea
            v-decorator="[
              'content',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>
<!-- 
        <a-form-item label="来信人">
          <a-input
            v-decorator="[
              'source',
              {
                initialValue: '匿名',
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item> -->

        <a-divider />

        <a-form-item label="回复">
          <a-textarea v-decorator="['reply']" />
        </a-form-item>

        <a-form-item label="处理方式">
          <a-radio-group v-decorator="['type']">
            <a-radio
              v-for="item in [
                {
                  name: '问题回复',
                },
                {
                  name: '确认完成',
                },
              ]"
              :key="item.name"
              :value="item.name"
            >
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button @click="$router.go(-1)">关闭</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import moment from "moment";
import { mapState } from "vuex";
function add(data) {
  return request({
    url: "/common-service/publicLetters/add",
    method: "post",
    data,
  });
}
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let status = "";

          if (values.reply) {
            if (!values.type) {
              this.$message.error("请选择处理方式");
              return;
            }
          }
          if (values.type) {
            if (!values.reply) {
              this.$message.error("请填写回复内容");
              return;
            }
          }

          if (!values.reply && !values.type) {
            status = "todo";
          }

          if (values.type === "问题回复") {
            status = "ongoing";
          } else if (values.type === "确认完成") {
            status = "complete";
          }

          this.saveLoading = true;

          add({
            title: values.title,
            content: values.content,
            // source: values.source,
            status,
            subList: values.reply
              ? [
                  {
                    content: values.reply,
                    type: values.type,
                    createBy: this.user.userName,
                    createAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                  },
                ]
              : [],
          })
            .then(() => {
              this.$router.go(-1);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 48px 160px;
}

.center {
  margin-top: 80px;
}
</style>
