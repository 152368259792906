var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 14 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"来信标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]),expression:"[\n              'title',\n              { rules: [{ required: true, message: '请输入！' }] },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"内容摘要"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'content',
              { rules: [{ required: true, message: '请输入！' }] },
            ]),expression:"[\n              'content',\n              { rules: [{ required: true, message: '请输入！' }] },\n            ]"}]})],1),_c('a-divider'),_c('a-form-item',{attrs:{"label":"回复"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reply']),expression:"['reply']"}]})],1),_c('a-form-item',{attrs:{"label":"处理方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type']),expression:"['type']"}]},_vm._l(([
                {
                  name: '问题回复',
                },
                {
                  name: '确认完成',
                },
              ]),function(item){return _c('a-radio',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }